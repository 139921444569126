import React from 'react'
import { useState, useEffect } from "react";
import { Footer } from '../Footer/Footer';
import { Helmet } from "react-helmet"
import Meta from "../Meta/Meta"

import Navbar from "../Navbar/Navbar";
import './About.css';
import Aboutareaiamge from '../Assets/images/about-4.png'
import HeaderImg from '../Assets/images/courses.html/online-certification-concept-with-books_23-2148573636.png'
import CertificateLogo1 from '../Assets/images/courses-detials/office-chair-svgrepo-com(2).svg'
import CertificateLogo2 from '../Assets/images/courses-detials/building-svgrepo-com.svg'
import technologyLogo from '../Assets/images/courses-detials/technology.svg'
import CertificateLogo0 from '../Assets/images/logo/google.png'
import instagram from "../Assets/images/social-media-icons/Instagram.svg";
import facebook from "../Assets/images/social-media-icons/Facebook.svg";
import linkedin from "../Assets/images/social-media-icons/Linkedin.svg";
import youtube from "../Assets/images/social-media-icons/Youtube.svg";
import twitter from "../Assets/images/social-media-icons/Twitter.svg";
import {getAllTrainer} from "../../Services/Auth/Actions/TrainerActions";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

import $ from "jquery";

//   const counters = document.querySelectorAll('.counter');
// const speed = 100; // The lower the slower
//   counters.forEach(counter => {
//     const updateCount = () => {
//       const target = +counter.getAttribute('data-target');
//       const count = +counter.innerText;
//       console.log(typeof target)
//       // Lower inc to slow and higher to slow
//       const inc = target / speed;
  
//       // console.log(inc);
//       // console.log(count);
  
//       // Check if target is reached
//       if (count < target) {
//         // Add inc to count and output in counter
//         counter.innerText = count + inc;
//         // Call function every ms
//         setTimeout(updateCount);
//       } else {
//         counter.innerText = target;
//       }
//     };
  
//     updateCount();
//   });



function About() {
  const [isVisible,setIsVisible]=useState(false)

const onChange=(isVisible)=>{
    console.log('Element is now %s', isVisible ? 'visible' : 'hidden');
    setIsVisible(isVisible)
}
  
const [trainer, setTrainer] = useState([]);
useEffect(() => {
  window.scrollTo(0, 0);
  getAllTrainer().then((data) => {
    setTrainer(data);
  });
  $(".navbar-toggler.navbar-light:not(.collapsed)").click()
  document.addEventListener('contextmenu', (e) => {
    e.preventDefault();
  });
}, []);

const galleryClick = () => {
  document.getElementById("profilemodal").style.display = "block";
}
const close=() => {
  document.getElementById("profilemodal").style.display = "none";
}
const [trainerData,settrainerData]=useState({})

    return (
        <div>
           <Meta title="Luminar Technolab |No.1 Software Training Institute in Kochi,Kerala" content="Our professional trainers with years of industry experience helps you get trained and placed in emerging technologies like Big Data, Data Science, Machine Learning, Python." />

        <section id="aboutus">
<div class="container">
    <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-5 courses-header-wrapper">
            <h1 class="courses-heading">
            Luminar Technolab            </h1>
            <h3 class="courses-sub-heading">
            No.1 Software Training Institute in Kochi,Kerala    </h3>
            <p class="courses-heading-paragraph">
            Our professional trainers with years of industry experience helps you get trained &amp;placed in emerging technologies like Big Data, Data Science, Machine Learning, Python.
            </p>
          </div>
        <div class="col-sm-12  col-md-7 courses-header-image-wrapper">
            <img src={HeaderImg} 
            alt="header-image-certification" class="header-image-certification" />
        </div>
    </div>
</div>
</section>
<section className='about-ar'>
<div className="about-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="about-container">
                <h3>Provide best education services for you</h3>
                <h4><b>Luminar Technolab - ISO 9001:2015 Certified Institution -  Software Training Institute.</b></h4>
                <p>Providing advanced level Gen 4.0 software training to students, Luminar Technolab is an IT finishing school situated at Kakkanad, 
                  near Infopark Cochin & Mavoor Road Calicut. Luminar Technolab is affiliated to National Council for Technology and Training (NACTET). NACTET is an autonomous organisation registered under 
                  Govt of Kerala and Government of India NCT, New Delhi under Indian trust act. </p>
                <p>Born out from a group IT professionals with more than 15 years of industry experience, Luminar Technolab offers best software training and placement 
                  in emerging technologies like Big Data Analytics, Machine Learning, Artificial Intelligence, Data Science, Automation Testing, Full Stack Development, 
                  Python, MEAN Stack, Digital Marketing, PHP, Angular, Power BI &amp; Tableau. </p>
                <p>Our mission is to provide 100% placements to students those who enrol for our specialised courses. The firm’s vision is to become the premium training 
                  centre that maximises value for customers by delivering high-quality training and placement services driven by intellect, innovation, and integrity in 
                  a fun and healthy environment.</p>
                <h4><b>Why Luminar Technolab</b></h4>
                <ul class="why-luminar-list aboutus">
                    <li> ISO 9001:2015 Certified Institution</li>
                    <li> Affiliated to NACTET
                        (http://www.nactetindia.org/affiliation.php)</li>
                    <li> Training by highly experienced and
                        certified professionals</li>
                    <li> No slideshow (PPT) training, fully
                        Hands-on training</li>
                    <li> Interactive session with interview QA’s
                    </li>
                    <li> Real-time projects scenarios &
                        Certification Help</li>
                    <li> Most competitive & affordable course
                        fees</li>
                    <li> Placement support for all courses</li>
                    <li> List of established & satisfied clients
                        & students</li>
                    <li> Located Near Infopark Cochin.</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5">
              {/*About Image Area Start*/}
              <div className="about-image-area img-full">
                <img src={Aboutareaiamge} alt="data science course in Kochi" />
              </div>
              {/*About Image Area End*/}
            </div>
          </div>
        </div>
      </div>
</section>
<section id="course-detials-prefer-us">
                <div class="container">
                  <div class="row">
                    <div class="col-12">
                      <h2 class="course-detials-prefer-uss">
                        Why should you prefer us
                      </h2>
                    </div>
                  </div>
                  <div class="row course-detials-prefer-us">
                    <div
                      class="col-6 col-md-3
                                course-detials-prefer-courses-card-section"
                    >
                      <div class="course-detials-prefer-courses">
                       
                         <VisibilitySensor onChange={onChange}><img
                          src={CertificateLogo0}
                          alt="certificate-logo"
                          class="course-detials-prefer-us-image-one"
                        />
       </VisibilitySensor>
       <div>{isVisible ? <div class="course-detials-prefer-count-one "><CountUp  end={1000} duration={3} />+</div> : null}</div>
                                                                     
                        <p class="course-detials-prefer-name">Google reviews</p>
                      </div>
                    </div>

                    <div
                      class="col-6 col-md-3 
                                course-detials-prefer-courses-card-section"
                    >
                      <div class="course-detials-prefer-courses">
                      <VisibilitySensor onChange={onChange}><img
                          src={technologyLogo}
                          alt="technology-logo"
                          class="course-detials-prefer-us-images
                                        two"
                        /></VisibilitySensor>
                       <div>{isVisible ? <div class="course-detials-prefer-count-one "><CountUp  end={30} duration={3} />+</div> : null}</div>
                        <p class="course-detials-prefer-name">Technologies</p>
                      </div>
                    </div>

                    <div
                      class="col-6 col-md-3 
                                course-detials-prefer-courses-card-section"
                    >
                      <div class="course-detials-prefer-courses">
                      <VisibilitySensor onChange={onChange}><img
                          src={CertificateLogo1}
                          alt="certificate-logo"
                          class="course-detials-prefer-us-images-three"
                        /></VisibilitySensor>
                        <div>{isVisible ? <div class="course-detials-prefer-count-one "><CountUp  end={1000} duration={3} />+</div> : null}</div>
                        <p class="course-detials-prefer-name">Placement</p>
                      </div>
                    </div>

                    <div
                      class="col-6 col-md-3 
                                course-detials-prefer-courses-card-section"
                    >
                      <div class="course-detials-prefer-courses">
                      <VisibilitySensor onChange={onChange}><img
                          src={CertificateLogo2}
                          alt="certificate-logo"
                          class="course-detials-prefer-us-images
                                        four"
                        /></VisibilitySensor>
                       <div>{isVisible ? <div class="course-detials-prefer-count-one "><CountUp  end={250} duration={3} />+</div> : null}</div>
                        <p class="course-detials-prefer-name">Companies</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
                
                <h1 className='about-card-header' id='aboutus-card'>Our Team</h1>

         <section className='aboutus-card' >
             
         <div class="container">
  <div class="row">
  {trainer &&
                trainer.map((customer, i) => {
                  return(
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div class="our-team">
                   <div onClick={()=>{
                      settrainerData(customer)
                      galleryClick()}}>
                   <div class="picture">
                        <img class="img-fluid" src={customer.image}/>
                      </div>
                      <div class="team-content">
                        <h3 class="name">{customer.name}</h3>
                        <h4 class="title">{customer.subject}</h4>
                      </div>
                   </div>
                      <ul class="social">
                      <a href={customer.instagramLink}><img src={instagram} class="img-fluid social-media-iconss  about-icon" alt="Instagram"/></a>
                      <a href={customer.facebookLink}>  <img src={facebook} class="img-fluid social-media-iconss  about-icon" alt="facebook"/></a>
                      <a href={customer?.youtubeLink}> <img src={youtube} class="img-fluid social-media-iconss  about-icon " alt="youtube"/></a>
                      <a href={customer.linkedInLink}> <img src={linkedin} class="img-fluid social-media-iconss  about-icon" alt="linkedin"/> </a>                           
                      </ul>
                    </div>
                  </div>
                  )
                }

                )}
    
            </div>

                <div className='profilemodal' id='profilemodal'>
                 <div className='profile-trainer-data'>
                  <div className='trainer-profile-container'>
                  <span class="close-gallery" onClick={close}>&times;</span>
                  <div className='trainer-profile'>
                  <div class="profile-picture">
                        <img class="img-fluid" src={trainerData.image}/>
                  </div>
                  <div class="team-content">
                  <h3 class="name">{trainerData.name}</h3>
                  <h4 class="title">{trainerData.subject}</h4>
                  <div class="desc">
                  {trainerData.description}
                  </div>
                 </div>
                 <ul class="profile-social">
                      <a href={trainerData.instagramLink}><img src={instagram} class="img-fluid social-media-iconss  about-icon" alt="Instagram"/></a>
                      <a href={trainerData.facebookLink}>  <img src={facebook} class="img-fluid social-media-iconss  about-icon" alt="facebook"/></a>
                      <a href={trainerData?.youtubeLink}> <img src={youtube} class="img-fluid social-media-iconss  about-icon " alt="youtube"/></a>
                      <a href={trainerData.linkedInLink}> <img src={linkedin} class="img-fluid social-media-iconss  about-icon" alt="linkedin"/> </a>                           
                 </ul>
                  </div>
                  </div>  
                 </div>
                </div>


</div>
         </section>
        <Footer/>
        </div>
    )
}

export default About


