import React, {useState} from 'react';
import Scrollcss from './Scroll.css'

const ScrollButton1 = () =>{

const [visible, setVisible] = useState(false)

const toggleVisible = () => {
	const scrolled = document.documentElement.scrollTop;
	if (scrolled > 300){
	setVisible(true)
	}
	else if (scrolled <= 300){
	setVisible(false)
	}
};

const scrollToTop = () =>{
	window.scrollTo({
	top: 0,
	behavior: 'smooth'
	
	});
};

window.addEventListener('scroll', toggleVisible);

return (
<button onClick={scrollToTop}
	style={{display: visible ? 'inline' : 'none'}} className="scroll-btn"> <i class="people-say fas fa-chevron-circle-up"></i></button>
);
}
export default ScrollButton1;
