import React from "react";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast,Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import instagram from "../Assets/images/social-media-icons/Instagram.svg";
import facebook from "../Assets/images/social-media-icons/Facebook.svg";
import linkedin from "../Assets/images/social-media-icons/Linkedin.svg";
import youtube from "../Assets/images/social-media-icons/Youtube.svg";
import twitter from "../Assets/images/social-media-icons/Twitter.svg";
import whatsapp from "../Assets/images/contact/whatsapp-svgrepo-com.svg";
import mail from "../Assets/images/contact/email-svgrepo-com.svg";
import phone from "../Assets/images/contact/phone-call-svgrepo-com.svg";
import { Grid, Box } from "@material-ui/core";
import map from "../Assets/images/contact/map-pin-svgrepo-com(1).svg";
import { createEnquiry } from "../../Services/Auth/Actions/ContactActions";
import { makeStyles } from "@material-ui/styles";
import Navbar from "../Navbar/Navbar";
import {Footer}  from "../Footer/Footer";
import './Thankyou.css'
const style = makeStyles({
  inputField: {
    height: "60px",
    height: "47px",
    border: "1px solid #BBBBBB",
    padding: "16px 18px",
  },
});

function SuccessPage() {

  const classes = style();
  return (
    <Box>
     
<body>
	<div class="card-thank">
      <div className="thank">
        <i class="checkmark">✓</i>
      </div>
        <h1>THANK YOU!</h1> 
        <p>Thanks a bunch for filling that out.<br></br> It means a lot to us, just like you do! We really appreciate you giving us a moment of your time today.Thanks for being you.</p>
      </div>

	{/* <footer class="site-footer" id="footer">
		<p class="site-footer__fineprint" id="fineprint">Copyright ©2014 | All Rights Reserved</p>
	</footer> */}
</body>
    </Box>
  );
}

export default SuccessPage;
