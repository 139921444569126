// const API = 'https://luminartechnolab-clnt-backend.herokuapp.com/api';
//const API ='http://localhost:9000/api'
 const API = 'https://api.luminartechnolab.com/api';

const axios = require('axios');
const headers = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
};



export const getAllCourses = (data) => { 
    return axios.get(`${API}/course/list?page=${data}&limit=${10}`)
   .then((data) => {
     return data.data.data;
   })
   .catch((err) => {
     return err
   });
};

export const getCourse = (id) =>{
  return axios.get(`${API}/course/${id}`).then((data)=>{
      return data.data
  }).catch((err)=>{
      return err.response.data
  })
}

export const getCourseByUrl = (url) =>{
  return axios.get(`${API}/course/${url}`).then((data)=>{
      return data.data
  }).catch((err)=>{
      return err.response.data
  })
}