import React from 'react';
import './Refund.css'
export default function Refund() {
  return <div className='container refund'>
<h4>Cancellation Policy</h4>
<p>
If you are not satisfied with our services, you have the liberty to cancel your admissions. For Cancellations please mail us to <a href="mailto:accounts@luminartechnolab.com">accounts@luminartechnolab.com .</a>
<br/>
Cancellation Requests will not be eligible for refund, however the admission can be cancelled.
</p>
<h4>Refund Policy</h4>

<p>Courses fees and Admission fees once Paid is not Refundable under any circumstances.</p>
 
  </div>;
}
