import React from 'react';
import './Certification.css'
export default function Certification() {
  return <div className='container certification'>
<h4>LuminarTechnolab is affiliated to National Council for Technology and Training (NACTET).</h4>

<p>National Council for Technology and Training (NACTET) is an autonomous organization registered under Govt of Kerala and Government of India NCT, New Delhi under the Indian trust act. Being a National level NGO, NACTET serves the country as a non-profit, non-political and non-sectarian organization.

NACTET 🎓 certificates have got high credibility among employers in India and abroad especially in the Middle East and other parts of the world. All certificates issued by NACTET can be attested by the Ministry of External affairs-Govt. of India and by the embassies of almost all countries.
</p>
<p>More Info: 👇</p>

<a href='http://www.nactetindia.org/attestation.php' target="_blank">http://www.nactetindia.org/attestation.php</a>
  </div>;
}
