import React from "react";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Link as RouterLink, Navigate, useNavigate, useParams } from 'react-router-dom';
import * as Yup from "yup";
import { ToastContainer, toast,Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Grid, Box, Button } from "@material-ui/core";
import { payment,sentMail } from "../../Services/Auth/Actions/PaymentActions";
import ulimage from '../Assets/images/courses.html/Group45.png'
import {getCourse} from '../../Services/Auth/Actions/CourseActions'
import Success from "./success";
import SuccessPage from './ThankyouPage'
import "./Payment.css";
import { makeStyles } from "@material-ui/styles";
const axios = require('axios');

const style = makeStyles({
  inputField: {
    height: "60px",
    height: "47px",
    border: "1px solid #BBBBBB",
    padding: "16px 18px",
  },
});

function Payment() {
  const { id } = useParams();
  const [course, setCourse] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getCourse(id).then((data) => {
      setCourse([data]);
    });
  }, []);
 const [name,setName] = useState('Mehul')
 function loadScript(src){
  return new Promise((resolve=>{
    const script = document.createElement('script')
    script.src="https://checkout.razorpay.com/v1/checkout.js"
    document.body.appendChild(script)
    script.onload= ()=>{
      resolve(true)
    }
    script.onerror=()=>{
      resolve(false)
    }
  }))
}
 async function displayRazorpay(values){

  const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
  if(!res){
    alert ('Razorpay SDK failed to load.Are you online?')
  }

const data =payment(values).then((datas) => {

  //  const data = await fetch('http://localhost:9000/api/payment/order', {method:'POST',body:values}).then((t)=>t.json())
    const options = {
      "key": 'rzp_live_tADna9sORaFrNT' ,//"rzp_test_Y1enzHkmVCmPBb", // Enter the Key ID generated from the Dashboard
      "amount": "50000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": "INR",
      "name": "Luminar technolab",
      "description": "Registration Fee",
      "image": {ulimage},
      "order_id": datas.orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      "handler": function (response){

        axios({
          method: "post",
          // url: `http://localhost:9000/api/payment/updateTransactionId`,
          // url: `https://luminartechnolab-clnt-backend.herokuapp.com/api/payment/updateTransactionId`,
          url: `https://api.luminartechnolab.com/api/payment/updateTransactionId`,


          data: {paymentId:response.razorpay_payment_id,orderId:response.razorpay_order_id},
          headers: { 'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'},
        })
          .then(function (response) {
            //handle success
            console.log(response);
    
          })
          .catch(function (response) {
            //handle error
            alert(response);
          });
    
            navigate('/success');
          // alert(response.razorpay_payment_id);
          // alert(response.razorpay_order_id);
          // alert(response.razorpay_signature)
          sentMail(datas.result.email,datas.orderId,values.title)
      },
      "prefill": {
          name:"Luminar",
          email:datas.result.email,
          contact:datas.result.phone,
          "image": {ulimage}

      },
    //   "theme": {
    //     "color": "green"
    // }
  };
  const paymentObject = new window.Razorpay(options);
  paymentObject.on('payment.failed', function (response){
    alert("Your payment transaction is failed ");

          // alert(response.error.code);
          // alert(response.error.description);
          // alert(response.error.source);
          // alert(response.error.step);
          // alert(response.error.reason);
          // alert(response.error.metadata.order_id);
          // alert(response.error.metadata.payment_id);
  });
  paymentObject.open();
  document.getElementById('rzp-button1').onclick = function(e){
      e.preventDefault();
  }
});
  }
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: ""
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("Required")
        .min(5, "Must be at least 5 characters long")
        .max(15, "Must be 15 characters or less")
        .required("Required"),
        lastName: Yup.string()
        .required("Required")
        .min(5, "Must be at least 5 characters long")
        .max(15, "Must be 15 characters or less")
        .required("Required"),
        phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid')
        .min(10, "Must be at least 10 characters long").required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
    }),
    onSubmit: (values, { resetForm }) => { 
    //   createEnquiry(values).then((datas) => {
    //     if (datas.name === "Error") {
    //       toast.error(datas.message, {
    //         position: "top-center",
    //         autoClose: 3000,
    //     },
    // );
    //       alert(datas.message);
    //     } else {
    //       toast.success("Submitted Successfully", {
    //         position: "top-center",
    //         autoClose: 3000,
    //     },);   
    //     console.log("asd",values) 
    //   displayRazorpay(values)  
    //  resetForm();
    //   }
    //   });
        values.fee=course[0].fee
        values.title=course[0].title

          displayRazorpay(values)  

    },
    handleSubmit: (event) => {
      event.preventDefault();
    },
  });

  return (

    <div   className="card-pay">
     <form onSubmit={formik.handleSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control
                    payment-input-field pay"
                    id="firstName"
                    placeholder="First Name"
                    name="firstName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <div>{formik.errors.firstName}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control
                    payment-input-field pay"
                    id="lastName"
                    placeholder="Last Name"
                    name="lastName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <div>{formik.errors.lastName}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control payment-input-field pay"
                    id="phone "
                    placeholder="Phone Number"
                    name="phone"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <div>{formik.errors.phone}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control
                    payment-input-field pay"
                    id="email-id"
                    placeholder="Email Id"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                </div>
                {formik.touched.email && formik.errors.email ? (
                  <div>{formik.errors.email}</div>
                ) : null}
                <div className="row contact-us-button-section-pay">
                  <div
                    class="col-12 d-flex
                            justify-content-center
                            align-items-center btn-div"
                  >
                    <button type="submit" class="btn-pay" id="rzp-button1" style={{height:'50px',width:'150px'}} >Pay</button>
                  </div>
                </div>
              </form>


    </div>
  )
}

export default Payment;
