import React from "react";

import './Trainingprograms.css'
const trainingprograms = (props) => {

    return (
        <div className="trainingprogramsContainer" >
            <img
                src={'/trainingprograms.jpeg'}
                alt="Fullscreen Image"
                style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    width: "auto",
                    height: "auto",
                }}
            />
        </div>
    );
};

export default trainingprograms;
