// const API = 'https://luminartechnolab-clnt-backend.herokuapp.com/api';
// const API ='http://localhost:9000/api'
const API = 'https://api.luminartechnolab.com/api';

const axios = require('axios');
const headers = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
};

export const getHomeDetails = () =>{

  return axios.get(`${API}/home/getDetails`).then((data)=>{
      return data.data
  }).catch((err)=>{
      return err.response.data
  })
}


export const getBannerDetails = () =>{
  return axios.get(`${API}/banner/getDetails`).then((data)=>{
      return data.data
  }).catch((err)=>{
      return err.response.data
  })
}


export const registerNowCreate = (data) =>{
  return axios.post(`${API}/register/create`,
      data,{headers}).then((data)=>{
      return data.data
  }).catch((err)=>{
      return err.response.data
  })
}
export const bookADemoCreate = (data) =>{
  return axios.post(`${API}/bookADemo/create`,
      data,{headers}).then((data)=>{
      return data.data
  }).catch((err)=>{
      return err.response.data   
  })
}

export const getInstaToken = () =>{
  console.log("token")
  return axios.get(`${API}/settings/getAccessToken`).then((data)=>{
      return data.data
  }).catch((err)=>{
      return err.response.data
  })
}