import logo from "./logo.svg";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from "./Component/Contact/ContactPage";
import { CourseList } from "./Component/CourseList/CourseList";
import { CourseDetails } from "./Component/CourseList/CourseDetails";
import { Placement } from "./Component/Placement/Placement";

import SuccessPage from "./Component/Payment/ThankyouPage";
import Navbar from "./Component/Navbar/Navbar";
import Home from "./Component/Home/Home";
import Payment from "./Component/Payment/Payment";
import About from "./Component/About/About";
import Terms from "./Component/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./Component/privacyPolicy/privacyPolicy";
import Gallery from "./Component/Gallery/Gallery";
import Trainingprograms from "./Component/Gallery/trainingprograms";
import Certification from "./Component/certification & Affiliation/Certification & Affiliation";
import EnquiryForms from "./Component/EnquiryForms/ContactPage";
import Refund from "./Component/Refund and Cancellation Policy/Refund and Cancellation Policy";
import ScrollButton1 from "./Component/Scroll/Scroll";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
function App() {
  toast.configure();
  const isEnquiryPage = window.location.pathname.includes("/enquiry");

const hideOrShowChatControllers = (isEnquiryPage) => {
  const chatIconLayout = document.getElementsByClassName("drift-frame-controller");
  const chatBoxLayout = document.getElementById("drift-frame-chat");
  if (chatIconLayout.length > 0 && chatBoxLayout) {
    chatIconLayout[0].style.display = isEnquiryPage ? "none" : "block";
    chatBoxLayout.style.display = isEnquiryPage ? "none" : "block";
  }
};

const observeChatCreation = (isEnquiryPage) => {
  const chatElement = document.getElementById("drift-frame-chat");
  if (chatElement) {
    hideOrShowChatControllers(isEnquiryPage);
  }
};

const chatObserverCallback = (mutationsList, observer) => {
  for (const mutation of mutationsList) {
    if (mutation.type === "childList") {
      observeChatCreation(isEnquiryPage);
    }
  }
};

const chatObserverOptions = { childList: true, subtree: true };
const chatObserver = new MutationObserver(chatObserverCallback);

useEffect(() => {
  observeChatCreation(isEnquiryPage);
  chatObserver.observe(document.body, chatObserverOptions);

  return () => {
    chatObserver.disconnect();
  };
}, [isEnquiryPage]);



  return (
    <BrowserRouter>
      <Navbar />
      {/* <Helmet>
        <title> Kerala's No.1 Software Training Institute in Kochi (Cochin) | Luminar Technolab </title>
        <meta name="description"
        content='Luminar Technolab is a No.1 Software Training Institute in Kochi, offering you Job Oriented courses on Data Science, Data Analytics, Machine Learning, Python, Big Data & Other Certified IT programs'
        />
        <meta name='keywords' content='Software Training Institute in Kochi, Data Science,  Data Analytics,  Machine Learning,  Python,  Big Data & Other Certified IT programs'/>
        </Helmet> */}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/placements" element={<Placement />} />
        <Route path="/about" element={<About />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/certification" element={<Certification />} />
        <Route path="/enquiry" element={<EnquiryForms />} />

        <Route path="/Refund" element={<Refund />} />
        <Route path="/termsAndConditions" element={<Terms />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/courses" element={<CourseList />} />
        <Route path="/course-detail/:url" element={<CourseDetails />} />
        <Route path="/home" element={<Home />} />
        <Route path="/trainingPrograms" element={<Trainingprograms />} />
        <Route path="/payment/:id" element={<Payment />} />
      </Routes>
      <ScrollButton1 />
    </BrowserRouter>
  );
}

export default App;
