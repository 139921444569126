import React from "react";
import { Helmet } from "react-helmet";

function Meta({
  title = " Kerala's No.1 Software Training Institute in Kochi (Cochin) | Luminar Technolab ",
  content = "Luminar Technolab is a No.1 Software Training Institute in Kochi & Mavoor Road Calicut, offering you Job Oriented courses on Data Science, Data Analytics, Machine Learning, Python, Big Data & Other Certified IT programs",
}) {
  return (
    <>
      <Helmet>
        <title>{title} </title>
        <meta name="description" content={content}></meta>
        <meta
          name="keywords"
          content="Software Training Institute in Kochi, Data Science,  Data Analytics,  Machine Learning,  Python,  Big Data & Other Certified IT programs"
        />
      </Helmet>
    </>
  );
}

export default Meta;
