import React  from 'react'
import brandlogo from "../Assets/images/logo/logo vanner wb (1).png"
import { useState, useEffect } from "react";
import Navbar from '../Navbar/Navbar'
import Meta from "../Meta/Meta"

import { Footer } from '../Footer/Footer'
import background from '../Assets/images/placement/webinar-concept-illustration_114360-4884.png'
import placmentone from '../Assets/images/placement/240_F_197116416_hpfTtXSoJMvMqU99n6hGP4xX0ejYa4M7.png'
import Instafeed from 'instafeed.js'
import { getAllPlacements} from "../../Services/Auth/Actions/PlacementActions";
import logos from "../Assets/images/logo/logo .1.svg"
import Slider from "react-slick";
import dns from '../Assets/images/logo/Dns-01.png'
import fingent from '../Assets/images/logo/fingent.png'
import wipro from '../Assets/images/logo/Wipro.png'
import tcs from '../Assets/images/logo/tcs-color.png'
import infosys from '../Assets/images/logo/infosys.png'
import cognizant from '../Assets/images/logo/Cognizant-Logo-PNG2.png'
import panapps from  '../Assets/images/logo/panapps.png'
import impelsys from '../Assets/images/logo/impelsys.jpeg'
import rapidvalue from '../Assets/images/logo/rapidvalue.png'
import nucore from '../Assets/images/logo/nucore.png'
import naico from '../Assets/images/logo/naico.png'
import jtsi from '../Assets/images/logo/jtsi.jpeg'
import tryzens from '../Assets/images/logo/tryzens.png'
import sementor from '../Assets/images/logo/sementor.png'
import testvox from '../Assets/images/logo/testvox.png'
import growthplug from '../Assets/images/logo/growthplug.png'
import grapelime from '../Assets/images/logo/grapelime.png'
import nuvento from '../Assets/images/logo/nuvento.png'
import vaisesika from '../Assets/images/logo/vaisesika.png'
import lanware from '../Assets/images/logo/lanware.jpeg'
import logicplum from '../Assets/images/logo/logicplum.png'
import techvantage from '../Assets/images/logo/techvantage.png'
import thomson from '../Assets/images/logo/thomson.png'
import hyundai from '../Assets/images/logo/hyundai.jpeg'
import gizemeon from '../Assets/images/logo/gizemeon.png'
import draup from '../Assets/images/logo/draup.png'
import enfin from '../Assets/images/logo/enfin.png'
import zeazonz from '../Assets/images/logo/zeazonz.png'
import logidots from '../Assets/images/logo/logidots.png'
import prudent from '../Assets/images/logo/prudent.png'
import beinex from '../Assets/images/logo/beinex.png'
import intpurple from '../Assets/images/logo/intpurple.png'
import ognitio from '../Assets/images/logo/ognitio.png'
import NewAgeSys from '../Assets/images/logo/NewAgeSys.png'
import claysys from '../Assets/images/logo/claysys.png'

import $ from "jquery";




import './Placement.css'
import Group27 from "../Assets/images/Group27.svg";
export const Placement = () => {
  const [placement, setPlacement] = useState([]);
const [pageNumber,setPageNumber]=useState(1)
const [nextPage,setNextPage]=useState(false)

const [popUp, setPopUp] = useState({});

  useEffect(() => {
    $(".navbar-toggler.navbar-light:not(.collapsed)").click()
    
reload()
    var feed = new Instafeed({
      accessToken: 'IGQVJYempYQ29hRVdkcDJvajJqX0pVYzZAjREdkNE5HWU9nZA0VJeFFCM0ptanZAfNEdYbkUycEtfVlA2RmkzSTBLVm82ZAmRfQ0tmWksxenlLeVNEUnEtZAXVXZAW50OVBtSnhKUmplNW9tWkdNWDNEUHZADcAZDZD'
    });
    feed.run();
  },[]);

const reload=()=>{
  getAllPlacements(pageNumber).then((response) => {
    console.log("response.length",response.length)
    setPlacement([...placement,...response]);
  });
  getAllPlacements(pageNumber+1).then((response) => {
 if(response.length>0){
  setNextPage(true)
 }
  });
}


 function popUpHandler(value){
let dataObj={}
dataObj.name=value.name;
dataObj.company=value.company;
dataObj.designation=value.designation;
dataObj.course=value.course;
dataObj.professionalCourse=value.professionalCourse;
dataObj.college=value.college;
dataObj.image=value.image;
dataObj.trainer=value.trainer
console.log(dataObj,'data obj')
  setPopUp(dataObj)
}



useEffect(() => {

  reload()

    },[pageNumber]);
    
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });

const incrementPageNumber=()=>{
  setPageNumber(pageNumber+1)
}

  var settings = {
    centerMode: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows:false,
    
  };


  return (
    <div class="placement-body">
              <Meta title="On-Job IT Training Institute | Assured Placement Assistance -Luminar Technolab" content="Get 100% Assured placement assistance after your training period. Learn Job Oriented IT courses such as Big data, Data science, Python, Mean Stack, Testing training from Luminar Technolab. " />
    <div class="container" id="navbar-color">
    </div>
    <header id="placement-header">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6">
                    <h1 class="placement-header-heading">On-Job IT </h1>
                    <p class="placement-header-subheading">
                    Training Institute | Assured Placement Assistance -Luminar Technolab</p>
                    <p class="placement-header-paragraph">Get 100% Assured placement assistance after your training period. Learn Job Oriented IT courses such as Big data, Data science, Python, Mean Stack, Testing training from Luminar Technolab. </p>
                </div>
                <div class="col-12 col-md-6 placement-header-image-section">
                    <img
                        src={background}
                        alt="placement-header-image"
                        class="placement-header-image"/>
                </div>
            </div>
        </div>
    </header>


    <section id="placement-for-students">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center p-0">
                    <h1 class="placement-for-students-heading">Placements</h1>
                    <p class="placement-for-students-subheading">
                        Our mission is to provide 100% placements to
                        students those who enrol for our specialised
                        courses.
                    </p>
                    <p class="placement-for-students-paragraph">
                        Our mission is to provide 100% placements to
                        students those who enrol for our specialised
                        courses. Our Placement assistance starts with
                        Resume
                        preparation, Mock Interviews by real working
                        professionals, Aptitude test training &
                        Interviews.
                        We will provide unlimited placement assistance
                        till
                        the student is placed satisfactorily.
                    </p>
                </div>
            </div>
        </div>
    </section>

    <section id="placement-for-students-card-section">
        <div class="container">
        <div>
        <div className="row">
          <div className="col-12">
            <p className="placement-for-students-placement-rate-heading
                                text-center">
              We had a 100% placement rate for those who
              enrolled in our most recent batch.
            </p>
          </div>
        </div>

      
        <div className="row">
        {placement &&
                placement.map((customer, i) => {

                  return(
          <div className="col-12 col-md-6
                            placement-for-student-card-section">
            {/* card section  */}
            <div className="card placement-for-student-card" data-toggle="modal" data-target="#placement-for-student-card-modal" value = {customer} onClick={() => popUpHandler(customer)} >
              <div className="card-body d-flex flex-row p-0">
                <div className="placement-for-student-card-image-section">
                  <img src={customer.image } alt="placement-for-student-card-image-one" className="placement-for-student-card-image" />
                </div>
                <div className="placement-for-student-card-text-section">
                  <div className="placement-for-student-card-text-wrapper">
                    <p className="placement-for-student-student-name">
                   {customer.name}</p>
                    <p className="placement-for-student-student-job-field">
                   {customer.designation}</p>
                    <p className="placement-for-student-student-company-name">
                     {customer.company}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          )})}
        </div>

        <div className="row">
                    <div className="modal fade" id="placement-for-student-card-modal" tabIndex={-1} aria-labelledby="placement-for-student-card-one-modal" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content placement-model-content">
                      
                        <div className="modal-body placement-modal-body">
                          <div className="modal-content-wrapper">
                            <div className="modal-brand-image">
                             
                              <img src={brandlogo} alt="modal-brand-image-logo" className="modal-brand-image-logo" />
                            </div>
                            <h1 className="modal-greeting-heading">Congratulations</h1>
                            <p className="modal-student-name">{popUp.name}</p>
                            <div className='modal-profile-image-section'>
                            <img src={popUp.image} alt="modal-profile-image" className="modal-profile-image rounded-circle" />
                            </div>
                            <div className="modal-placement-info
                                                        text-center">
                              <p className="modal-placed-paragraph">For
                                placement in </p>
                              <p className="modal-placed-in">
                                {popUp.company} </p>
                              <p className="modal-placed-as">{popUp.designation}</p>
                            </div>
                            <div className="modal-placement-student-detials">
                              <div className="modal-student-detials">
                                <p className="modal-student-criteria
                                                              ">Qualification:
                                  <span className="modal-student-data">
                                   {popUp.professionalCourse}</span></p>
                                {/* <p className="modal-student-criteria">Graduated
                                  year:
                                  <span className="modal-student-data">
                                    2019</span></p> */}
                                <p className="modal-student-criteria">College:
                                  <span className="modal-student-data">
                                   {popUp.college} </span></p>
                                <p className="modal-student-criteria">Course:
                                  <span className="modal-student-data">{popUp.course?.title}</span></p>
                                <p className="modal-student-criteria">
                                  Trainers:
                                  <span className="modal-student-data
                                                                    last">{popUp.trainer}
                                  </span></p>
                              </div>
                            </div>
                            <p className="modal-student-footer
                                                        text-center">#LUMINARPLACEMENTS</p>
                          </div>
                        </div>
                          
                      </div>
                    </div>
                  </div>


         {nextPage&&
           <button className="placement-for-students-placement-rate-button" onClick={()=>incrementPageNumber()}>
           LOAD MORE</button>
         }
        
        </div>

        
      </div>
            <div class="row">
                        <div class="col-12 text-center">
                            <div
                                class="placement-for-students-our-student-working-on">
                                <h1
                                    class="placement-for-students-our-student-working">
                                    Our students working on</h1>
                                <p
                                    class="placement-for-students-our-student-working-paragraph">Our
                                    mission is to provide 100% placements to
                                    students
                                    those who enrol for our specialised
                                    courses.</p>
                            </div>
                            <div class="home-card-logos-placement">
            <div class="container-fluid">
              <div className="row">
                {/* <div className="col-md-3 placement-slick-head">
                  <img src={whitelogo}/>
                   <h1> Placements</h1>
                </div> */}
                <div className="col-md-12 placement-logos">
                <Slider { ...settings}>
                <div class="col logoipusum-image-col">
                  <img src={dns} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={fingent} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={wipro} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={tcs} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={infosys} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={cognizant} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={panapps} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={impelsys} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={rapidvalue} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={nucore} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={naico} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={jtsi} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={tryzens} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={sementor} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={testvox} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={grapelime} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={growthplug} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={nuvento} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={vaisesika} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={lanware} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={logicplum} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={techvantage} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={thomson} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={hyundai} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={gizemeon} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={draup} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={enfin} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={zeazonz} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={logidots} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={prudent} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={beinex} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={intpurple} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={ognitio} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={NewAgeSys} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={claysys} class="logoipusum-image" alt="logos" />
                </div>
                </Slider>
                </div>
              </div>
                
             
               
                {/* <div class="col logoipusum-image-col">
                  <img src={Group27} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={Group27} class="logoipusum-image" alt="logos" />
                </div> */}
              
            </div>
          </div>
                        </div>
                    </div> 
        </div>
    </section>

<Footer/>

</div>
  )
}
