import React from "react";
import map from "../Assets/images/contact/map-pin-svgrepo-com(1).svg";
const ContactUsMapPopup = ({ modalId, mapURL }) => {
  return (
    <div style={{ margin: "5px" }}>
      <div
        className="contact-us-content"
        data-toggle="modal"
        data-target={`#${modalId}`}
        style={{ width: "20em" }}
      >
        <span
          className="contact-us-circle"
          style={{ width: "50px", height: "50px" }}
        >
          <img src={map} alt="map-icon" />
        </span>
        <div className="contact-us-text">
          <p className="contact-us-subheading">Locate us on:</p>
          <p className="contact-us-paragraph">Google Map</p>
        </div>
        {/* map  pop-up start */}
        <div
          className="modal fade"
          id={modalId}
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <span class="close">&times;</span>
            <div className="modal-content">
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe
                    className="gmap_iframe"
                    frameBorder={0}
                    scrolling="no"
                    marginHeight={0}
                    marginWidth={0}
                    src={mapURL}
                  />
                  <a href="https://www.fridaynightfunkin.net/friday-night-funkin-mods-fnf-play-online/">
                    FNF Mods
                  </a>
                </div>
                <style
                  dangerouslySetInnerHTML={{
                    __html:
                      ".mapouter{position:relative;text-align:right;width:600px;height:400px;}.gmap_canvas {overflow:hidden;background:none!important;width:600px;height:400px;}.gmap_iframe {width:600px!important;height:400px!important;}",
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* mapa now pop-up start */}
      </div>
    </div>
  );
};

export default ContactUsMapPopup;
