import React from 'react';
import {
    BrowserRouter,
    Routes,
    NavLink,
    Link
  } from "react-router-dom";
  import './Navbar.css'
import logos from "../Assets/images/logo/logo vanner 0.1.png"
import { useLocation } from "react-router-dom";
import * as ReactBootStrap from "react-bootstrap";

function Navbar() {
    //assigning location variable
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");
    return (

<div class="contact">
            <div class="container" id="navbar-color">

                <nav class="navbar navbar-expand-lg">
                    <div class="container">
                        <a class="navbar-brand" href="#">
                        <Link to="/"> <img src={logos}
                                alt="luminar-logo"
                                class="img-fluid logos-navbar"/></Link>
                        </a>
                         

                        <button class="navbar-toggler navbar-light collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarNav" aria-controls="navbarNav"
                            aria-expanded="false"
                            aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>

                        <div class="collapse navbar-collapse" id="navbarNav">
                            <ul class="navbar-nav">
                            <li  className={splitLocation[1] == "" ? "nav-item active" : "nav-item"}>
                                 <a class="nav-link"><Link to="/">Home</Link></a>
                                </li>
                            <li  className={splitLocation[1] == "courses" ? "nav-item active" : "nav-item"}>
                                 <a class="nav-link"><Link to="/courses">Courses</Link></a>
                                </li>
                                <li className={splitLocation[1] == "placements" ? "nav-item active" : "nav-item"}>
                                 <a class="nav-link"><Link to="/placements"> Placements</Link></a> 
                                </li>
                                <li  className={splitLocation[1] == "gallery" ? "nav-item active" : "nav-item"}>
                                 <a class="nav-link"><Link to="/gallery">Gallery</Link></a> 
                                </li>
                                <li  className={splitLocation[1] == "about" ? "nav-item active" : "nav-item"}>
                                 <a class="nav-link"><Link to="/about">About us</Link></a> 
                                </li>
                                <li  className={splitLocation[1] == "contact" ? "nav-item active" : "nav-item"}>
                                 <a class="nav-link"><Link to="/contact">Contact</Link></a> 
                                </li>
                                
                                {/* <li class="nav-item" className={splitLocation[1] == "payment" ? "active" : ""}>
                                <Link to="/payment">  <a class="nav-link">Payment</a> </Link>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>

    )
}

export default Navbar