import React from "react";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { getAllCourses } from "../../Services/Auth/Actions/CourseActions";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import instagram from "../Assets/images/social-media-icons/Instagram.svg";
import facebook from "../Assets/images/social-media-icons/Facebook.svg";
import linkedin from "../Assets/images/social-media-icons/Linkedin.svg";
import youtube from "../Assets/images/social-media-icons/Youtube.svg";
import twitter from "../Assets/images/social-media-icons/Twitter.svg";
import whatsapp from "../Assets/images/contact/whatsapp-svgrepo-com.svg";
import mail from "../Assets/images/contact/email-svgrepo-com.svg";
import phone from "../Assets/images/contact/phone-call-svgrepo-com.svg";
import { Grid, Box } from "@material-ui/core";
import map from "../Assets/images/contact/map-pin-svgrepo-com(1).svg";
import {
  createEnquiryForms,
  sentMailEnquiryForms,
} from "../../Services/Auth/Actions/ContactActions";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import "./ContactPage.css";
import { makeStyles } from "@material-ui/styles";
import Navbar from "../Navbar/Navbar";
import { Footer } from "../Footer/Footer";
import $ from "jquery";
import Meta from "../Meta/Meta";
import {
  createEnquiryLuminarCRM,
  getDropDownListLuminarCRM,
} from "../../Services/CRMservice";
import {
  howDidYouKnowAboutLuminar,
  yearArray,
} from "../../Services/utils/crmUtils";
const style = makeStyles({
  inputField: {
    height: "60px",
    height: "47px",
    border: "1px solid #BBBBBB",
    padding: "16px 18px",
  },
});

function EnquiryForms() {
  const classes = style();
  //const [showButton, setShowButton] = useState(true);
  const [showError, setShowError] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [others, setOthers] = useState(false);
  const [courseList, setCourseList] = useState([]);
  const [courseLocation, setCourseLocation] = useState([]);
  const [qualifications, setQualification] = useState([]);
  const navigate = useNavigate();

  const [enquiry, setEnquiry] = useState([
    {
      enquiries: [],
      fullName: "",
      phoneNumber: "",
      email: "",
      qualification: "",
      passoutYear: "",
      college: "",
      address: "",
      refferal: "",
      refferalOthers: "",
      course: "",
      location: "",
    },
  ]);

  const [captcha_number, setCaptcha_number] = useState(0);
  useEffect(() => {
    // loadCaptchaEnginge(6);
    getDropDownListLuminarCRM("course").then((data) => {
      setCourseList(data);
    });
    getDropDownListLuminarCRM("batch-location").then((data) => {
      setCourseLocation(data);
    });
    getDropDownListLuminarCRM("leads-qualification").then((data) => {
      setQualification(data);
    });
    $(".navbar-toggler.navbar-light:not(.collapsed)").click();
    $(".drift-conductor-item iframe").css("display", "none");
  }, []);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const {
    enquiries,
    fullName,
    phoneNumber,
    email,
    course,
    message,
    qualification,
    passoutYear,
    college,
    address,
    refferal,
    refferalOthers,
    location,
  } = enquiry;
  // const handleChange = (name) => (e) => {
  //   e.preventDefault();
  //   setEnquiry({ ...enquiry, [name]: e.target.value });
  // };

  const formik = useFormik({
    initialValues: {
      fullName: "",
      phoneNumber: "",
      email: "",
      qualification: "",
      passoutYear: "",
      college: "",
      address: "",
      refferal: "",
      refferalOthers: "",
      course: "",
      location: "",
    },
    //enableReinitialize:true,
    validationSchema: Yup.object({
      fullName: Yup.string()
        .trim()
        .required("Required")
        .min(3, "Must be at least 3 characters long")
        .max(25, "Must be 25 characters or less")
        .required("Required"),
      phoneNumber: Yup.string()
        .trim()
        .matches(phoneRegExp, "Phone number is not valid")
        .min(10, "Must be at least 10 characters long")
        .max(13)
        .required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      qualification: Yup.string()
        .trim()
        .required("Required")
        .min(2, "Must be at least 2 characters long")
        .max(30, "Must be 30 characters or less")
        .required("Required"),
      passoutYear: Yup.string().trim().required("Required"),
      course: Yup.string()
        .trim()
        .required("Required")
        .min(2, "Must be at least 2 characters long")
        // .max(15, "Must be 15 characters or less")
        .required("Required"),

      college: Yup.string().trim().required("Required"),
      address: Yup.string()
        .trim()
        .required("Required")
        .min(2, "Must be at least 2 characters long")
        // .max(15, "Must be 15 characters or less")
        .required("Required"),
      refferal: Yup.string()
        .trim()
        .required("Required")
        .min(2, "Must be at least 2 characters long")
        // .max(15, "Must be 15 characters or less")
        .required("Required"),
      refferalOthers:
        others &&
        Yup.string()
          .trim()
          .required("Required")
          .min(2, "Must be at least 2 characters long")
          .required("Required"),
      location: Yup.string()
        .trim()
        .required("Required")
        .min(2, "Must be at least 2 characters long")
        // .max(15, "Must be 15 characters or less")
        .required("Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      //   let user_captcha_value =
      //  document.getElementById("user_captcha_input").value;

      // if (validateCaptcha(user_captcha_value) == true) {

      // setShowButton(true)

      const payload = {
        name: values.fullName,
        contactNumber: values.phoneNumber,
        email: values.email,
        course: values.course,
        passOutYear: values.passoutYear,
        college: values.college,
        address: values.address,
        howDidYouHearAboutLuminar: values.refferal,
        preferedLocation: values.location,
        leadsQualification: values.qualification,
      };
      createEnquiryLuminarCRM(payload).then((datas) => {
        if (datas.success) {
          toast.success("Submitted Successfully", {
            position: "top-center",
            autoClose: 5000,
          });
          resetForm();
          //  setShowButton(false);
          setShowWarning(false);
          //  document.getElementById('user_captcha_input').value=''
          sentMailEnquiryForms(
            values.email,
            values.fullName,
            values.phoneNumber,
            values.course,
            values.qualification,
            values.passoutYear,
            values.college,
            values.address,
            values.refferal,
            values.location
          );
          // loadCaptchaEnginge(6,"white","black","");
        } else {
          toast.error(datas.message, {
            position: "top-center",
            autoClose: 5000,
          });
          alert(datas.message);
        }
      });
    },
    handleSubmit: (event) => {
      event.preventDefault();
    },
  });
  return (
    <Box>
      <div id="contact-us">
        {/* <Helmet>
<title>Contact Luminar Technolab| No.1 Software Training Institute Near Infopark,Kochi </title>
<meta name="description" content=" Luminar Technolab provides job- oriented training & focuses on preparing candidates as per industry needs. Get trained &amp; placed in leading IT Companies with us. "></meta>
        </Helmet> */}

        <Meta
          title="Contact Luminar Technolab| No.1 Software Training Institute Near Infopark,Kochi "
          content="Luminar Technolab provides job- oriented training and focuses on preparing candidates as per industry needs. Get trained and placed in leading IT Companies with us. "
        />
        <div className="container">
          <div className="row contact-us-container">
            <div className="col-12 col-md-5">
              <h1 className="contact-us-heading">Enquiry Form</h1>

              <div className="contact-us-content">
                <a href="tel:+91 8086266660">
                  <span className="contact-us-circle">
                    <img src={phone} alt="phone-icon" />
                  </span>

                  <div className="contact-us-text">
                    <p className="contact-us-subheading">Call us on:</p>
                    <p className="contact-us-paragraph">
                      Call: +91 8086 651 651
                    </p>
                  </div>
                </a>
              </div>

              <div className="contact-us-content">
                <span className="contact-us-circle">
                  <img src={mail} alt="email-icon" />
                </span>
                <div className="contact-us-text">
                  <p className="contact-us-subheading">Email us at:</p>
                  <a href="mailto:contact@luminartechnolab.com">
                    <p className="contact-us-paragraph ipad-email">
                      Enquiry: contact@luminartechnolab.com
                    </p>
                  </a>
                  <a href="mailto:hrteam@luminartechnolab.com">
                    <p className="contact-us-paragraph ipad-email">
                      Placement Cell: hrteam@luminartechnolab.com
                    </p>
                  </a>
                </div>
              </div>

              <div className="contact-us-content">
                <a
                  href="https://api.whatsapp.com/send/?phone=918086651651&text&type=phone_number&app_absent=0"
                  target="_blank"
                >
                  <span className="contact-us-circle">
                    <img src={whatsapp} alt="whatsapp-icon" />
                  </span>

                  <div className="contact-us-text">
                    <p className="contact-us-subheading">Connect us on:</p>
                    <p className="contact-us-paragraph">WhatsApp</p>
                  </div>
                </a>
              </div>

              <div
                className="contact-us-content"
                data-toggle="modal"
                data-target="#map"
              >
                <span className="contact-us-circle">
                  <img src={map} alt="map-icon" />
                </span>
                <div className="contact-us-text">
                  <p className="contact-us-subheading">Locate us on:</p>
                  <p className="contact-us-paragraph">Google Map</p>
                </div>
                {/* map  pop-up start */}
                <div
                  className="modal fade"
                  id="map"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="exampleModalCenterTitle"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <span class="close">&times;</span>
                    <div className="modal-content">
                      <div className="mapouter">
                        <div className="gmap_canvas">
                          <iframe
                            className="gmap_iframe"
                            frameBorder={0}
                            scrolling="no"
                            marginHeight={0}
                            marginWidth={0}
                            src="https://maps.google.com/maps?width=600&height=400&hl=en&q=luminar technolab kochi&t=&z=14&ie=UTF8&iwloc=B&output=embed"
                          />
                          <a href="https://www.fridaynightfunkin.net/friday-night-funkin-mods-fnf-play-online/">
                            FNF Mods
                          </a>
                        </div>
                        <style
                          dangerouslySetInnerHTML={{
                            __html:
                              ".mapouter{position:relative;text-align:right;width:600px;height:400px;}.gmap_canvas {overflow:hidden;background:none!important;width:600px;height:400px;}.gmap_iframe {width:600px!important;height:400px!important;}",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* mapa now pop-up start */}
              </div>
              {/* <div className="row-container">
                <div className="col-container">
                  <p className="contact-us-subheading">
                    Cochin
                  </p>
                  <p class="text-left footer-address">
                   3rd Floor, Vallamattam Estate, Seaport - Airport Rd, CSEZ,
                    Kakkanad, Cochin, Kerala - 682037
                    <p class="text-left footer-paragraph">
                      Call: +91 808 665 1651
                    </p>
                    <p
                      class="text-left
                                        footer-paragraph"
                    >
                      Email: contact@luminartechnolab.com
                    </p>
                  </p>
                </div>
                <div className="col-container-two">
                <p className="contact-us-subheading">
                    Calicut
                  </p>
                  <p class="text-left footer-address">
                     2nd Floor, Mullath Building Opp.
                    KSRTC Bus Stand Mavoor Road, Calicut, Kerala - 673001
                    <p class="text-left footer-paragraph">
                      Call: +91 9916534343 | +91 9778004133
                    </p>
                    <p
                      class="text-left
                                        footer-paragraph"
                    >
                      Email: contact@luminartechnolab.com
                    </p>
                  </p>
                </div>
              </div> */}

              <div className="row">
                <div className="col-12">
                  <div className="contact-us-social-media">
                    <p className="contact-us-connect">Connect with us</p>
                    <div className="social-icons">
                      <a
                        href="https://www.instagram.com/luminartechnolab/"
                        target="_blank"
                      >
                        <img
                          src={instagram}
                          class="img-fluid social-media-iconsss"
                          alt="Instagram"
                        />{" "}
                      </a>
                      <a
                        href="https://www.facebook.com/luminartechnolab"
                        target="_blank"
                      >
                        <img
                          src={facebook}
                          class="img-fluid social-media-icons"
                          alt="Facebook"
                        />
                      </a>
                      <a
                        href="https://www.youtube.com/c/LuminarTechnolab"
                        target="_blank"
                      >
                        <img
                          src={youtube}
                          class="img-fluid social-media-icons"
                          alt="Youtube"
                        />
                      </a>
                      <a
                        href="https://twitter.com/luminartechno/"
                        target="_blank"
                      >
                        <img
                          src={twitter}
                          class="img-fluid social-media-icons"
                          alt="Twitter"
                        />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/luminartechnolab/?originalSubdomain=in"
                        target="_blank"
                      >
                        <img
                          src={linkedin}
                          class="img-fluid social-media-icons"
                          alt="Linkedin"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-7 small-device-contact-header">
              <h1 className="contact-us-heading-enquiry-form">Enquiry Form</h1>

              <p className="contact-us-column-right-heading">
                How can we be of assistance?
              </p>
              <p className="contact-us-column-right-paragraph">
                If you require clarifications on any of the courses or the
                admission process, we are happy to help
              </p>
              <form onSubmit={formik.handleSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control
                        contact-us-input-field"
                    id="fullName"
                    placeholder="Full Name"
                    name="fullName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.fullName}
                  />
                  {formik.touched.fullName && formik.errors.fullName ? (
                    <div className="error-messge">{formik.errors.fullName}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control
                                contact-us-input-field"
                    id="contact-number"
                    placeholder="Contact Number"
                    name="phoneNumber"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phoneNumber}
                  />
                  {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                    <div className="error-messge">
                      {formik.errors.phoneNumber}
                    </div>
                  ) : null}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control
                                contact-us-input-field"
                    id="email-id"
                    placeholder="Email Id"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="error-messge">{formik.errors.email}</div>
                  ) : null}
                </div>

                <div className="form-group">
                  <select
                    class="form-control
                              contact-us-input-field select-filed "
                    name="qualification"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.qualification}
                    required
                  >
                    <option value="">Qualification</option>
                    {qualifications &&
                      qualifications.map((item, i) => {
                        return (
                          <option value={item.id} label={item.label}></option>
                        );
                      })}
                  </select>
                  {formik.touched.qualification &&
                  formik.errors.qualification ? (
                    <div className="error-messge">
                      {formik.errors.qualification}
                    </div>
                  ) : null}
                </div>

                <div className="form-group">
                  <select
                    class="form-control
                              contact-us-input-field select-filed "
                    name="passoutYear"
                    onChange={formik.handleChange}
                    // onChange={(e) => {
                    //   if (e.target.value == "Others") {
                    //     setOthers(true);
                    //     // return

                    //   }
                    //   formik.handleChange(e);
                    // }}
                    onBlur={formik.handleBlur}
                    value={formik.values.passoutYear}
                    required
                  >
                    <option value="">Passout Year</option>
                    {yearArray &&
                      yearArray.map((item, i) => {
                        return (
                          <option value={item.id} label={item.label}></option>
                        );
                      })}
                  </select>
                  {formik.touched.passoutYear && formik.errors.passoutYear ? (
                    <div className="error-messge">
                      {formik.errors.passoutYear}
                    </div>
                  ) : null}
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control
                        contact-us-input-field"
                    id="college"
                    placeholder="College"
                    name="college"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.college}
                  />
                  {formik.touched.college && formik.errors.college ? (
                    <div className="error-messge">{formik.errors.college}</div>
                  ) : null}
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control
                        contact-us-input-field"
                    id="address"
                    placeholder="Address"
                    name="address"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.address}
                  />
                  {formik.touched.address && formik.errors.address ? (
                    <div className="error-messge">{formik.errors.address}</div>
                  ) : null}
                </div>

                <div className="form-group">
                  <select
                    class="form-control
                              contact-us-input-field select-filed "
                    name="refferal"
                    onChange={formik.handleChange}
                    // onChange={(e) => {
                    //   if (e.target.value == "Others") {
                    //     setOthers(true);
                    //     // return

                    //   }
                    //   formik.handleChange(e);
                    // }}
                    onBlur={formik.handleBlur}
                    value={formik.values.refferal}
                    required
                  >
                    <option value="">How do you hear about us</option>
                    {howDidYouKnowAboutLuminar &&
                      howDidYouKnowAboutLuminar.map((item, i) => {
                        return (
                          <option value={item.id} label={item.label}></option>
                        );
                      })}
                  </select>
                  {formik.touched.refferal && formik.errors.refferal ? (
                    <div className="error-messge">{formik.errors.refferal}</div>
                  ) : null}
                </div>

                <div className="form-group">
                  <select
                    class="form-control
                              contact-us-input-field select-filed "
                    name="course"
                    //  onChange={formik.handleChange}
                    onChange={(e) => {
                      if (e.target.value == "Others") {
                        setOthers(true);
                        // return
                      }
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.course}
                    required
                  >
                    <option value="">Course looking for?</option>
                    {courseList &&
                      courseList.map((item, i) => {
                        return (
                          <option value={item.id} label={item.name}></option>
                        );
                      })}
                  </select>
                  {formik.touched.course && formik.errors.course ? (
                    <div className="error-messge">{formik.errors.course}</div>
                  ) : null}
                </div>

                {others && (
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control
                                        contact-us-input-field"
                      id="refferalOthers"
                      placeholder="Type course here"
                      name="refferalOthers"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.refferalOthers}
                    />
                    {formik.touched.refferalOthers &&
                    formik.errors.refferalOthers ? (
                      <div className="error-messge">
                        {formik.errors.refferalOthers}
                      </div>
                    ) : null}
                  </div>
                )}

                <div className="form-group">
                  <select
                    class="form-control
                              contact-us-input-field select-filed "
                    name="location"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.location}
                    required
                  >
                    <option value="">Choose Location</option>

                    {courseLocation &&
                      courseLocation.map((item, i) => {
                        return (
                          <option value={item.id} label={item.label}></option>
                        );
                      })}
                  </select>
                  {formik.touched.location && formik.errors.location ? (
                    <div className="error-messge">{formik.errors.location}</div>
                  ) : null}
                </div>

                <div className="row contact-us-button-section">
                  {/* <div
                    class="col-12 d-flex"         
                  >
                    <div id="form-group" className="form-submit">
                      <div className="col mt-3 contact-recaptcha">
                        <LoadCanvasTemplate />
                      </div>
                      <div className="col mt-3">
                        {/* <div className="recaptcha-input">
                          <input
                            placeholder="Enter Captcha Value"
                            id="user_captcha_input"
                            name="user_captcha_input"
                            type="text"
                            onChange={()=>setShowError(false)}
                          ></input>
                        </div> */}
                  {/* </div>
                    </div>
                    <button class="contact-us-submit-button submit-button " type="submit" disabled={!showButton}>
                    SUBMIT
                  </button> 
                  </div>  */}
                  <button
                    class="contact-us-submit-button submit-button "
                    type="submit"
                    // disabled={!showButton}
                  >
                    SUBMIT
                  </button>
                  {/* <div class="col-12 d-flex justify-content-center align-items-center">
                    <button
                      class="contact-us-submit-button submit-button-mobile"
                      type="submit"
                      disabled={!showButton}
                    >
                      SUBMIT
                    </button>
                  </div> */}

                  {showError && <p class="text-danger">Wrong Captcha</p>}
                  {showWarning && <p class="text-danger">Enter Captcha</p>}
                </div>
              </form>
            </div>
          </div>

          <div className="row"></div>
        </div>
      </div>
      <Footer />
    </Box>
  );
}

export default EnquiryForms;
