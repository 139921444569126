import React from 'react';
import { Footer } from '../Footer/Footer';
import { useState, useEffect } from "react";
import backgroundgallery from  '../Assets/images/home/01.jpg';
import HeaderImg from '../Assets/images/courses.html/online-certification-concept-with-books_23-2148573636.png'
import Slider from "react-slick";
import Meta from "../Meta/Meta"

import dns from '../Assets/images/logo/Dns-01.png'
import {getGalleryNoPagination} from "../../Services/Auth/Actions/TestimonialActions"
import $ from "jquery";
import cognizant from '../Assets/images/logo/Cognizant-Logo-PNG2.png'
import './Gallery.css'
export default function Gallery() {

  const [gallery, setGallery] = useState([]);

    var settings = {
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 3,
        centerMode: true,
        autoplay: true,
        autoplaySpeed: 1500,
        arrows:false,
      };
      useEffect(() => {
        $(".navbar-toggler.navbar-light:not(.collapsed)").click()
        window.scrollTo(0, 0);
        getGalleryNoPagination().then((response) => {
          setGallery([...gallery, ...response]);
        });
        document.addEventListener('contextmenu', (e) => {
          e.preventDefault();
        });
      }, []);

      const galleryClick = () => {
        document.getElementById("gallerymodal").style.display = "block";
      }
      const close=() => {
        document.getElementById("gallerymodal").style.display = "none";
      }
      const [img,setImg]=useState()
      const [title,setTitle]=useState("")
  return (
    <div>
 <Meta title="Big Data- Hadoop Training | Best Data Science Training in Kochi " content="Luminar Technolab is a leading training institute in Kochi offering advanced IT courses such as Big Data, Data Science, Mean Stack, Python, Testing training etc. " />

    <header id="placement-header">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6">
                    <h1 class="placement-header-heading">Big Data- Hadoop| Best Data Science  </h1>
                    <p class="placement-header-subheading">
                    Training in Kochi</p>
                    <p class="placement-header-paragraph">
                    Luminar Technolab is a leading training institute in Kochi offering advanced IT courses such as Big Data, Data Science, Mean Stack, Python, Testing training etc.                     </p>
                </div>
                <div class="col-12 col-md-6 placement-header-image-section">
                    <img
                        src={backgroundgallery}
                        alt="placement-header-image"
                        class="placement-header-image gallery-header"/>
                </div>
            </div>
        </div>
    </header>

<section id="gallery">
  <div className='container-fluid'>
      <div className='row'>
          <div className='col-12 gallery-section'>
          <Slider { ...settings}>
      {gallery &&
                        gallery.map((data, i) => {
                          return(
                <div class="col gallery-images">
                  {/* <img src={data.image} class="gallery-image" alt="logos" onClick={onClick} />  */}
                  <img src={data.image} class="gallery-image" alt="logos" onClick={() =>{
                    setImg(data.image)
                    setTitle(data.title)
                    galleryClick()} 
                  }/>
                  {/* <div class="overlay">
                    <h4>Trainers</h4>
                  </div> */}
                </div>
                
                          )})}
                </Slider>
                <div className='gallerymodal' id='gallerymodal'>
                 <div className='modal-gallery'>
                <span class="close-gallery" onClick={close}>&times;</span>
                 <img src={img} className='modals-content'/>
                 <div id="caption">{title}</div>
                 </div>
                </div>
                            </div>
                       </div>
                       </div>
</section>
  <Footer/>
    </div>
  )
         
}
