export const howDidYouKnowAboutLuminar = [
  { id: "Facebook", label: "Facebook", value: "Facebook" },
  { id: "Instagram", label: "Instagram", value: "Instagram" },
  { id: "Google Search", label: "Google Search", value: "Google Search" },
  { id: "Web Site", label: "Web Site", value: "Web Site" },
  { id: "Friend Referral", label: "Friend Referral", value: "Friend Referral" },
  { id: "Sulekha", label: "Sulekha", value: "Sulekha" },
  { id: "Youtube", label: "Youtube", value: "Youtube" },
  { id: "News Paper", label: "News Paper", value: "News Paper" },
  { id: "Yet5", label: "Yet5", value: "Yet5" },
  { id: "Others", label: "Others", value: "Others" },
];

export const yearArray = [
  { id: "2009", label: "2009", value: "2009" },
  { id: "2010", label: "2010", value: "2010" },
  { id: "2011", label: "2011", value: "2011" },
  { id: "2012", label: "2012", value: "2012" },
  { id: "2013", label: "2013", value: "2013" },
  { id: "2014", label: "2014", value: "2014" },
  { id: "2015", label: "2015", value: "2015" },
  { id: "2016", label: "2016", value: "2016" },
  { id: "2017", label: "2017", value: "2017" },
  { id: "2018", label: "2018", value: "2018" },
  { id: "2019", label: "2019", value: "2019" },
  { id: "2020", label: "2020", value: "2020" },
  { id: "2021", label: "2021", value: "2021" },
  { id: "2022", label: "2022", value: "2022" },
  { id: "2023", label: "2023", value: "2023" },
  { id: "2024", label: "2024", value: "2024" },
  { id: "2025", label: "2025", value: "2025" },
];
