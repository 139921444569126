import React from "react";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { getAllCourses } from "../../Services/Auth/Actions/CourseActions";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import instagram from "../Assets/images/social-media-icons/Instagram.svg";
import facebook from "../Assets/images/social-media-icons/Facebook.svg";
import linkedin from "../Assets/images/social-media-icons/Linkedin.svg";
import youtube from "../Assets/images/social-media-icons/Youtube.svg";
import twitter from "../Assets/images/social-media-icons/Twitter.svg";
import whatsapp from "../Assets/images/contact/whatsapp-svgrepo-com.svg";
import mail from "../Assets/images/contact/email-svgrepo-com.svg";
import phone from "../Assets/images/contact/phone-call-svgrepo-com.svg";
import { Grid, Box, CircularProgress } from "@material-ui/core";
import map from "../Assets/images/contact/map-pin-svgrepo-com(1).svg";
import {
  createEnquiry,
  sentMail,
} from "../../Services/Auth/Actions/ContactActions";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import "./ContactPage.css";
import { makeStyles } from "@material-ui/styles";
import Navbar from "../Navbar/Navbar";
import { Footer } from "../Footer/Footer";
import $ from "jquery";
import Meta from "../Meta/Meta";
import {
  createEnquiryLuminarCRM,
  getDropDownListLuminarCRM,
} from "../../Services/CRMservice";
import ContactUsMapPopup from "./ContactUsMapPopup";
const style = makeStyles({
  inputField: {
    height: "60px",
    height: "47px",
    border: "1px solid #BBBBBB",
    padding: "16px 18px",
  },
});

function ContactPage() {
  const classes = style();
  const [showButton, setShowButton] = useState(true);
  const [showError, setShowError] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const navigate = useNavigate();

  const [enquiry, setEnquiry] = useState([
    {
      enquiries: [],
      fullName: "",
      phoneNumber: "",
      email: "",
      city: "",
      course: "",
      message: "",
    },
  ]);
  const [captcha_number, setCaptcha_number] = useState(0);
  useEffect(() => {
    // loadCaptchaEnginge(6);
    getDropDownListLuminarCRM("course").then((data) => {
      setCourseList(data);
    });
    $(".navbar-toggler.navbar-light:not(.collapsed)").click();
    $(".drift-conductor-item iframe").css("display", "none");
  }, []);

  // const doSubmit = () => {
  //   let user_captcha_value =
  //     document.getElementById("user_captcha_input").value;

  //   if (validateCaptcha(user_captcha_value) == true) {
  //     // alert("Captcha Matched");
  //     setShowButton(true)
  //   } else {
  //     // alert("Captcha Does Not Match");
  //     setShowError(true)
  //   }
  // };

  const [courseList, setCourseList] = useState([]);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const { enquiries, fullName, phoneNumber, email, city, course, message } =
    enquiry;
  // const handleChange = (name) => (e) => {
  //   e.preventDefault();
  //   setEnquiry({ ...enquiry, [name]: e.target.value });
  // };

  const formik = useFormik({
    initialValues: {
      fullName: "",
      phoneNumber: "",
      email: "",
      city: "",
      course: "",
      message: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string()
        .trim()
        .required("Required")
        .min(3, "Must be at least 3 characters long")
        .max(25, "Must be 25 characters or less")
        .required("Required"),
      phoneNumber: Yup.string()
        .trim()
        .matches(phoneRegExp, "Phone number is not valid")
        .min(10, "Must be at least 10 characters long")
        .max(13)
        .required("Required"),
      email: Yup.string().email("Invalid email address"),
      city: Yup.string()
        .trim()
        .min(2, "Must be at least 2 characters long")
        .max(15, "Must be 15 characters or less"),
      course: Yup.string()
        .trim()
        .required("Required")
        .min(2, "Must be at least 2 characters long"),
      // .max(15, "Must be 15 characters or less")

      message: Yup.string().trim().min(2, "Must be at least 2 characters long"),
    }),
    onSubmit: (values, { resetForm }) => {
      //   let user_captcha_value =
      //  document.getElementById("user_captcha_input").value;

      // if (validateCaptcha(user_captcha_value) == true) {

      // setShowButton(true)
      // payload for contact us
      const payload = {
        name: values.fullName,
        contactNumber: values.phoneNumber,
        city: values.city,
        email: values.email,
        course: values.course,
        message: values.message,
      };
      createEnquiryLuminarCRM(payload).then((datas) => {
        if (datas.success) {
          toast.success("Submitted Successfully", {
            position: "top-center",
            autoClose: 5000,
          });
          resetForm();
          setShowButton(false);
          setShowWarning(false);
          //  document.getElementById('user_captcha_input').value=''
          sentMail(
            values.email,
            values.fullName,
            values.phoneNumber,
            values.course,
            values.city,
            values.message
          );
          // loadCaptchaEnginge(6,"white","black","");
        } else {
          toast.error(datas.message, {
            position: "top-center",
            autoClose: 5000,
          });
          alert(datas.message);
        }
      });
      // }
      //  else if(user_captcha_value.length==0){
      //    setShowWarning(true)
      //   }
      //   else {

      // alert("Captcha Does Not Match");
      //    setShowError(true)
      //    setShowWarning(false)

      // }
    },
    handleSubmit: (event) => {
      event.preventDefault();
    },
  });
  return (
    <Box>
      <div id="contact-us">
        {/* <Helmet>
<title>Contact Luminar Technolab| No.1 Software Training Institute Near Infopark,Kochi </title>
<meta name="description" content=" Luminar Technolab provides job- oriented training & focuses on preparing candidates as per industry needs. Get trained &amp; placed in leading IT Companies with us. "></meta>
        </Helmet> */}

        <Meta
          title="Contact Luminar Technolab| No.1 Software Training Institute Near Infopark,Kochi "
          content="Luminar Technolab provides job- oriented training and focuses on preparing candidates as per industry needs. Get trained and placed in leading IT Companies with us. "
        />
        <div className="container">
          <div className="row contact-us-container">
            <div className="col-12 col-md-5">
              <h1 className="contact-us-heading-contact-form">Contact Us</h1>

              <div className="contact-us-content">
                <a href="tel:+91 8086266660">
                  <span className="contact-us-circle">
                    <img src={phone} alt="phone-icon" />
                  </span>

                  <div className="contact-us-text">
                    <p className="contact-us-subheading">Call us on:</p>
                    <p className="contact-us-paragraph">
                      Call: +91 8086 651 651
                    </p>
                  </div>
                </a>
              </div>

              <div className="contact-us-content">
                <span className="contact-us-circle">
                  <img src={mail} alt="email-icon" />
                </span>
                <div className="contact-us-text">
                  <p className="contact-us-subheading">Email us at:</p>
                  <a href="mailto:contact@luminartechnolab.com">
                    <p className="contact-us-paragraph ipad-email">
                      Enquiry: contact@luminartechnolab.com
                    </p>
                  </a>
                  <a href="mailto:hr@luminartechnolab.com">
                    <p className="contact-us-paragraph ipad-email">
                      Placement Cell: hr@luminartechnolab.com
                    </p>
                  </a>
                </div>
              </div>

              <div className="contact-us-content">
                <a
                  href="https://api.whatsapp.com/send/?phone=918086651651&text&type=phone_number&app_absent=0"
                  target="_blank"
                >
                  <span className="contact-us-circle">
                    <img src={whatsapp} alt="whatsapp-icon" />
                  </span>

                  <div className="contact-us-text">
                    <p className="contact-us-subheading">Connect us on:</p>
                    <p className="contact-us-paragraph">WhatsApp</p>
                  </div>
                </a>
              </div>

              <div
                className="contact-us-content"
                data-toggle="modal"
                data-target="#map"
              >
                <span className="contact-us-circle">
                  <img src={map} alt="map-icon" />
                </span>
                <div className="contact-us-text">
                  <p className="contact-us-subheading">Locate us on:</p>
                  <p className="contact-us-paragraph">Google Map</p>
                </div>
                {/* map  pop-up start */}
                <div
                  className="modal fade"
                  id="map"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="exampleModalCenterTitle"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <span class="close">&times;</span>
                    <div className="modal-content">
                      <div className="mapouter">
                        <div className="gmap_canvas">
                          <iframe
                            className="gmap_iframe"
                            frameBorder={0}
                            scrolling="no"
                            marginHeight={0}
                            marginWidth={0}
                            src="https://maps.google.com/maps?width=600&height=400&hl=en&q=luminar technolab kochi&t=&z=14&ie=UTF8&iwloc=B&output=embed"
                          />
                          <a href="https://www.fridaynightfunkin.net/friday-night-funkin-mods-fnf-play-online/">
                            FNF Mods
                          </a>
                        </div>
                        <style
                          dangerouslySetInnerHTML={{
                            __html:
                              ".mapouter{position:relative;text-align:right;width:600px;height:400px;}.gmap_canvas {overflow:hidden;background:none!important;width:600px;height:400px;}.gmap_iframe {width:600px!important;height:400px!important;}",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* mapa now pop-up start */}
              </div>
              <div className="row-container">
                <div className="col-container">
                  <p className="contact-us-subheading">Cochin</p>
                  <p class="text-left footer-address">
                    Luminar Technolab Pvt Ltd
                    <br />
                    Ground Floor,K7 REALTY,
                    <br />
                    Juma masjid road, <br />
                    Seaport - Airport Rd, near Opp: District Panchayat, <br />
                    Kakkanad, Kochi, Kerala - 682030 <br />
                    <a href="tel:+91 808 665 1651">
                      <p class="text-left footer-paragraph">
                        Call: +91 8086 651 651
                      </p>
                    </a>
                    <a href="mailto:contact@luminartechnolab.com">
                      <p class="text-left footer-paragraph">
                        Email: contact@luminartechnolab.com
                      </p>
                    </a>
                  </p>
                  <ContactUsMapPopup
                    modalId={"Kakkanad"}
                    mapURL={
                      "https://maps.google.com/maps?width=600&height=400&hl=en&q=luminar technolab kochi&t=&z=14&ie=UTF8&iwloc=B&output=embed"
                    }
                  />
                </div>

                <div className="col-container">
                  <p className="contact-us-subheading">Calicut</p>
                  <p class="text-left footer-address">
                    Luminar Technolab Pvt Ltd <br />
                    2nd Floor, 61/10222, <br />
                    Mullath Building, <br />
                    Opp. KSRTC Bus Stand, <br />
                    <span style={{ whiteSpace: "nowrap" }}>
                      Mavoor Road, Calicut, Kerala - 673001{" "}
                    </span>
                    <br />
                    <a href="tel:+91 808 653 1531">
                      <p class="text-left footer-paragraph">
                        Call: +91 8086 531 531, 0495 - 3573001
                      </p>
                    </a>
                    <a href="mailto:contact@luminartechnolab.com">
                      <p class="text-left footer-paragraph">
                        Email: contact@luminartechnolab.com
                      </p>
                    </a>
                  </p>
                  <ContactUsMapPopup
                    modalId={"calicut"}
                    mapURL={
                      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d500877.12950207025!2d75.7118438780829!3d11.253864272847476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba65900d568d853%3A0x86dc9f15ee869de3!2sLuminar%20Technolab%20-%20Python%20Training%2C%20Artificial%20Intelligence%20Training%2C%20Full%20Stack%20Training%2C%20Flutter%20Training%20in%20Calicut!5e0!3m2!1sen!2sin!4v1724929960020!5m2!1sen!2sin"
                    }
                  />
                </div>
                <div className="col-container">
                  <p className="contact-us-subheading">Trivandrum </p>
                  <p class="text-left footer-address">
                    Luminar Technolab Pvt Ltd
                    <br />
                    3rd Floor, Nikunjam Saras Building,
                    <br />
                    opp. Kerala PSC office, <br />
                    Pattom, <br />
                    Thiruvananthapuram, Kerala 695004 <br />
                    <a href="tel:+91 6235651651">
                      <p class="text-left footer-paragraph">
                        Call: +91 6235651651
                      </p>
                    </a>
                    <a href="mailto:trivandrum@luminartechnolab.com">
                      <p class="text-left footer-paragraph">
                        Email: trivandrum@luminartechnolab.com
                      </p>
                    </a>
                  </p>
                  <ContactUsMapPopup
                    modalId={"trivandrum"}
                    mapURL={
                      "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d3945.793203316856!2d76.9422054!3d8.5194486!3m2!1i1024!2i768!4f13.1!3m2!1m1!2s!5e0!3m2!1sen!2sin!4v1724928980876!5m2!1sen!2sin"
                    }
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="contact-us-social-media">
                    <p className="contact-us-connect">Connect with us</p>
                    <div className="social-icons">
                      <a
                        href="https://www.instagram.com/luminartechnolab/"
                        target="_blank"
                      >
                        <img
                          src={instagram}
                          class="img-fluid social-media-iconsss"
                          alt="Instagram"
                        />{" "}
                      </a>
                      <a
                        href="https://www.facebook.com/luminartechnolab"
                        target="_blank"
                      >
                        <img
                          src={facebook}
                          class="img-fluid social-media-icons"
                          alt="Facebook"
                        />
                      </a>
                      <a
                        href="https://www.youtube.com/c/LuminarTechnolab"
                        target="_blank"
                      >
                        <img
                          src={youtube}
                          class="img-fluid social-media-icons"
                          alt="Youtube"
                        />
                      </a>
                      <a
                        href="https://twitter.com/luminartechno/"
                        target="_blank"
                      >
                        <img
                          src={twitter}
                          class="img-fluid social-media-icons"
                          alt="Twitter"
                        />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/luminartechnolab/?originalSubdomain=in"
                        target="_blank"
                      >
                        <img
                          src={linkedin}
                          class="img-fluid social-media-icons"
                          alt="Linkedin"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-12 col-md-7 small-device-contact-header"
              style={{ height: "90%" }}
            >
              <p className="contact-us-column-right-heading">
                How can we be of assistance?
              </p>
              <p className="contact-us-column-right-paragraph">
                If you require clarifications on any of the courses or the
                admission process, we are happy to help
              </p>
              <form onSubmit={formik.handleSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control
                        contact-us-input-field"
                    id="fullName"
                    placeholder="Name"
                    name="fullName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.fullName}
                  />
                  {formik.touched.fullName && formik.errors.fullName ? (
                    <div className="error-messge">{formik.errors.fullName}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control
                                contact-us-input-field"
                    id="contact-number"
                    placeholder="Contact Number"
                    name="phoneNumber"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phoneNumber}
                  />
                  {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                    <div className="error-messge">
                      {formik.errors.phoneNumber}
                    </div>
                  ) : null}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control
                                contact-us-input-field"
                    id="email-id"
                    placeholder="Email Id"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                </div>
                {formik.touched.email && formik.errors.email ? (
                  <div className="error-messge">{formik.errors.email}</div>
                ) : null}
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control
                                contact-us-input-field"
                    id="district"
                    placeholder="City"
                    name="city"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                  />
                  {formik.touched.city && formik.errors.city ? (
                    <div className="error-messge">{formik.errors.city}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  {/* <input
                    type="text"
                    className="form-control
                                contact-us-input-field"
                    id="intrest"
                    placeholder="Course"
                    name="course"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.course}
                  /> */}
                  <select
                    class="form-control
                              contact-us-input-field select-filed "
                    name="course"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.course}
                    required
                  >
                    <option value="">Please select Course</option>
                    {courseList &&
                      courseList.map((item, i) => {
                        return (
                          <option value={item.id} label={item.name}></option>
                        );
                      })}
                  </select>
                  {formik.touched.course && formik.errors.course ? (
                    <div className="error-messge">{formik.errors.course}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <textarea
                    type="text"
                    className="form-control message"
                    id="enquiry"
                    placeholder="Enquiry / Message / Questions"
                    rows="8"
                    name="message"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                  ></textarea>
                  {formik.touched.message && formik.errors.message ? (
                    <div className="error-messge">{formik.errors.message}</div>
                  ) : null}
                </div>
                <div className="row contact-us-button-section">
                  {/* <div
                    class="col-12 d-flex"
                            
                  >
                 
                    
                    <div id="form-group" className="form-submit">
                      <div className="col mt-3 contact-recaptcha">
                        <LoadCanvasTemplate />
                      </div>
                      <div className="col mt-3">
                        {/* <div className="recaptcha-input">
                          <input
                            placeholder="Enter Captcha Value"
                            id="user_captcha_input"
                            name="user_captcha_input"
                            type="text"
                            onChange={()=>setShowError(false)}
                          ></input>
                        </div> */}
                  {/* </div>
                    </div>
                    <button class="contact-us-submit-button submit-button " type="submit" disabled={!showButton}>
                    SUBMIT
                  </button> 
                  </div>  */}
                  <button
                    class="contact-us-submit-button submit-button "
                    type="submit"
                    //  disabled={!showButton}
                  >
                    {formik.isSubmitting ? (
                      <CircularProgress size={15} color="#6D1D8E" />
                    ) : (
                      "SUBMIT"
                    )}
                  </button>
                  {/*   */}

                  {showError && <p class="text-danger">Wrong Captcha</p>}
                  {showWarning && <p class="text-danger">Enter Captcha</p>}
                </div>
              </form>
            </div>
          </div>

          <div className="row"></div>
        </div>
      </div>
      <Footer />
    </Box>
  );
}

export default ContactPage;
