import React from "react";
import { useState, useRef, useEffect } from "react";
import "./Footer.css";
import instagram from "../Assets/images/social-media-icons/Instagram.svg";
import facebook from "../Assets/images/social-media-icons/Facebook.svg";
import linkedin from "../Assets/images/social-media-icons/Linkedin.svg";
import youtube from "../Assets/images/social-media-icons/Youtube.svg";
import twitter from "../Assets/images/social-media-icons/Twitter.svg";
import google from "../Assets/images/social-media-icons/GooglePlus.svg";
import logo from "../Assets/images/logo/logo.png";
import logos from "../Assets/images/logo/logo vanner 0.1.png";
import Img1 from "../Assets/images/home/nctt.png";
import Img2 from "../Assets/images/home/iso.png";
import { registerNowCreate } from "../../Services/Auth/Actions/HomeActions";
import { getAllCourses } from "../../Services/Auth/Actions/CourseActions";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { openAdmissionFormCRM } from "../../Services/CRMservice";

export const Footer = () => {
  const [courseList, setCourseList] = useState([]);
  const [registerNow, setRegisterNow] = useState({
    name: "",
    email: "",
    phone: "",
    courseName: "",
  });
  const [flag, setFlag] = useState(false);

  const { name, email, phone, courseName } = registerNow;
  useEffect(() => {
    getAllCourses().then((data) => {
      setCourseList(data);
    });
  }, []);
  const handleChange = (name) => (e) => {
    e.preventDefault();
    setRegisterNow({
      ...registerNow,
      [name]: e.target.value,
    });
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();

    if (registerNow.courseName.trim().length == 0) {
      alert("Please select one course");
      return;
    }
    registerNowCreate(registerNow)
      .then((data) => {
        console.log("submitted successfully", data);
        setRegisterNow({
          name: "",
          email: "",
          phone: "",
          courseName: "",
        });
        toast.success("Submitted Successfully", {
          position: "top-center",
          autoClose: 3000,
        });
        setFlag(false);
      })
      .catch((err) => {
        toast.error("Submission Failed", {
          position: "top-center",
          autoClose: 3000,
        });
      });
    // }
    // else{
    //   console.log("error inm validation")
    // }
  };
  // },[])
  return (
    <div>
      <section id="join-now-section">
        <div class="join-now-background">
          <div
            class="container d-flex
                                    justify-content-center"
          >
            <div
              class="row justify-content-center
                                        align-items-center"
            >
              <div class="col-12 overflow-text">
                <div class="text-center">
                  <p class="join-now-heading">
                    So why wait? Start one of our high quality courses from the
                    world's leading experts today!
                  </p>

                  <p class="join-now-callus">
                    If You Have Any Questions Call Us / Whatsapp On +91 80866
                    51651
                  </p>
                  <button
                    class="join-now-button"
                    // data-toggle="modal"
                    // data-target="#joinnow"
                    // onClick={() => {
                    //   setRegisterNow({
                    //     name: "",
                    //     email: "",
                    //     phone: "",
                    //     courseName: "",
                    //   });
                    //   setFlag(true);
                    // }}
                    onClick={() => openAdmissionFormCRM()}
                  >
                    JOIN NOW
                  </button>
                  {/* join now pop-up start */}
                  {flag && (
                    <div
                      className="modal fade"
                      id="joinnow"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="exampleModalCenterTitle"
                      aria-hidden="true"
                    >
                      <div
                        className="modal-dialog modal-dialog-centered"
                        // className=" modal-dialog-centered"
                        role="document"
                      >
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="exampleModalLongTitle"
                            >
                              Join Now
                            </h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={() => setFlag(false)}
                            >
                              <span aria-hidden="true" className="x">
                                ×
                              </span>
                            </button>
                          </div>
                          <form onSubmit={formSubmitHandler} id="frmStudent">
                            <div className="modal-body form-group m-2 form-group">
                              <input
                                type="text"
                                placeholder="Name"
                                className="form-control m-3 "
                                name={name}
                                value={name}
                                pattern="^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*"
                                required
                                minLength={3}
                                maxLength={20}
                                onChange={handleChange("name")}
                              ></input>
                              <br />
                              <input
                                type="email"
                                placeholder="Email"
                                className="form-control m-3"
                                name={email}
                                value={email}
                                // pattern='/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i'
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                size="30"
                                required
                                onChange={handleChange("email")}
                              ></input>
                              <br />
                              <input
                                type="text"
                                placeholder="Phone.No"
                                className="form-control m-3"
                                name={phone}
                                value={phone}
                                pattern="\d*"
                                minlength="10"
                                maxlength="13"
                                title="Field accepts only Numbers"
                                required
                                onChange={handleChange("phone")}
                              ></input>
                              <br />
                              <select
                                class="browser-default custom-select m-3 "
                                name={courseName}
                                value={courseName}
                                minLength={2}
                                maxLength={10}
                                onChange={handleChange("courseName")}
                                required
                              >
                                <option value="">Please select Course</option>
                                {courseList &&
                                  courseList.map((item, i) => {
                                    return (
                                      <option value={item.title}>
                                        {item.title}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-modal-color btn-lg m-2"
                                data-dismiss="modal"
                                onClick={() => setFlag(false)}
                              >
                                Close
                              </button>
                              <button
                                type="submit"
                                className="btn btn-modal-color btn-lg m-2"
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* join now pop-up start */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="mx-0 px-0">
        <div class="footer-container bg-white">
          <div class="row mx-0">
            <div class="col-sm-2 p-0 mt-3">
              <img
                src={logos}
                class="img-fluid footer-brand-image"
                alt="footer-logo"
              />

              <div
                class="col-sm-12 d-flex
              justify-content-start  pt-3
                   social-media-icons-wrapper"
              >
                <a
                  href="https://www.instagram.com/luminartechnolab/"
                  target="_blank"
                >
                  <img
                    src={instagram}
                    class="img-fluid social-media-iconss"
                    alt="Instagram"
                  />{" "}
                </a>
                <a
                  href="https://www.facebook.com/luminartechnolab"
                  target="_blank"
                >
                  <img
                    src={facebook}
                    class="img-fluid social-media-icons"
                    alt="Facebook"
                  />
                </a>
                <a
                  href="https://www.youtube.com/c/LuminarTechnolab"
                  target="_blank"
                >
                  <img
                    src={youtube}
                    class="img-fluid social-media-icons"
                    alt="Youtube"
                  />
                </a>
                <a href="https://twitter.com/luminartechno/" target="_blank">
                  <img
                    src={twitter}
                    class="img-fluid social-media-icons"
                    alt="Twitter"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/luminartechnolab/?originalSubdomain=in"
                  target="_blank"
                >
                  <img
                    src={linkedin}
                    class="img-fluid social-media-icons"
                    alt="Linkedin"
                  />
                </a>
              </div>
              <div
                class="row
                                            footer-certificate-logo"
              >
                <div
                  class="col-5 p-0
                                                footer-certificate-logo-small-device-left"
                >
                  <img
                    src={Img1}
                    alt="certificate logo"
                    class="
                                                    footer-certificate-logo-image-one"
                  />
                </div>
                <div
                  class="col-5 p-0
                                                footer-certificate-logo-small-device-right"
                >
                  <img
                    src={Img2}
                    alt="certificate logo"
                    class="
                                                    footer-certificate-logo-image-two"
                  />
                </div>
              </div>
            </div>

            <div class="col-sm-3">
              <p class="course pt-2 px-0">COURSES</p>
              <hr class="footer-hr" />
              <div class="row footer-courses">
                {courseList &&
                  courseList.map((item, i) => {
                    return (
                      <div class="col-sm-12">
                        <a href={`/course-detail/${item.url}`}>{item.title}</a>
                        {/* <Link to ={`/course-detail/${item._id}`}>{item.title} </Link> */}
                      </div>
                    );
                  })}
              </div>

              <div class="accordion large-screen" id="accordion-footer">
                <div class="card">
                  <div
                    class="accordion-footer
                                                    card-header p-0"
                    id="accordion-footer-heading-one"
                  >
                    <h2 class="mb-0">
                      <button
                        class="btn-block
                                                            accordion-footer-heading"
                        type="button"
                        data-toggle="collapse"
                        data-target="#footer-accordion"
                        aria-expanded="true"
                        aria-controls="footer-accordion"
                      >
                        COURSES{" "}
                        <i
                          class="fas
                                                                fa-angle-down"
                        ></i>
                      </button>
                    </h2>
                  </div>

                  <div
                    id="footer-accordion"
                    class="collapse
                                                    show"
                    aria-labelledby="accordion-footer-heading-one"
                    data-parent="#accordion-footer"
                  >
                    <div
                      class="card-body
                                                        footer-accordion px-0"
                    >
                      {courseList &&
                        courseList.map((item, i) => {
                          return (
                            <div class="col-12">
                              <a href={`/course-detail/${item._id}`}>
                                {item.title}
                              </a>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div
                    class="accordion-footer
                                                    card-header p-0"
                    id="accordion-footer-heading-one"
                  >
                    <h2 class="mb-0">
                      <button
                        class="btn-block
                                                            accordion-footer-heading"
                        type="button"
                        data-toggle="collapse"
                        data-target="#footer-accordions"
                        aria-expanded="true"
                        aria-controls="footer-accordions"
                      >
                        USEFULL LINKS{" "}
                        <i
                          class="fas
                                                                fa-angle-down"
                        ></i>
                      </button>
                    </h2>
                  </div>

                  <div
                    id="footer-accordions"
                    class="collapse
                                                    show"
                    aria-labelledby="accordion-footer-heading-one"
                    data-parent="#accordion-footer"
                  >
                    <div
                      class="card-body
                                                        footer-accordion px-0"
                    >
                      <div
                        class="col-sm-12
                                                footer-courses-our-facility"
                      >
                        <Link to="/about" className="footer-link">
                          Teachers & Staff
                        </Link>
                        <Link to="/gallery" className="footer-link">
                          Gallery
                        </Link>
                      </div>

                      <div
                        class="col-sm-12
                                                footer-courses-our-facility"
                      >
                        <Link to="/courses" className="footer-link">
                          Our Courses
                        </Link>
                        <Link to="/Refund" className="footer-link-01">
                          Refund & Cancellation Policy
                        </Link>
                      </div>

                      <div
                        class="col-sm-12
                                                footer-courses-our-facility"
                      >
                        <Link to="/certification" className="footer-link-01">
                          Certification & Affiliation
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div
                    class="accordion-footer
                                                    card-header p-0"
                    id="accordion-footer-heading-one"
                  >
                    <h2 class="mb-0">
                      <button
                        class="btn-block
                                                            accordion-footer-heading"
                        type="button"
                        data-toggle="collapse"
                        data-target="#footer-accordions1"
                        aria-expanded="true"
                        aria-controls="footer-accordions1"
                      >
                        CONTACT US{" "}
                        <i
                          class="fas
                                                                fa-angle-down"
                        ></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="footer-accordions1"
                    class="collapse show"
                    aria-labelledby="accordion-footer-heading-one"
                    data-parent="#accordion-footer"
                  >
                    <div class="card-body footer-accordion px-0">
                      {/* <div class="col-sm-12 footer-courses-our-facility">  */}
                      <p class="course1 pt-2 px-0">Cochin</p>
                      <p class="text-left footer-address">
                        Luminar Technolab Pvt Ltd <br />
                        Ground Floor,K7 REALTY,
                        <br />
                        Juma masjid road, <br />
                        Seaport - Airport Rd, near Opp: District Panchayat,{" "}
                        <br />
                        Kakkanad, Kochi, Kerala - 682030 <br />
                        <a href="tel:+91 808 665 1651">
                          <p class="text-left footer-paragraph">
                            Call: +91 8086 651 651
                          </p>
                        </a>
                        <a href="mailto:contact@luminartechnolab.com">
                          <p class="text-left footer-paragraph">
                            Email: contact@luminartechnolab.com
                          </p>
                        </a>
                      </p>
                      {/* </div> */}

                      <p class="course1 pt-2 px-0">Calicut</p>

                      <p class="text-left footer-address">
                        Luminar Technolab Pvt Ltd <br />
                        2nd Floor, 61/10222, <br />
                        Mullath Building, <br />
                        Opp. KSRTC Bus Stand ,<br />
                        <span style={{ whiteSpace: "nowrap" }}>
                          Mavoor Road, Calicut, Kerala-673001
                        </span>
                        <a href="tel:+91 8086 531 531">
                          <p class="text-left footer-paragraph">
                            Call:+91 8086 531 531, 0495 - 3573001
                          </p>
                        </a>
                        <a href="mailto:contact@luminartechnolab.com">
                          <p
                            class="text-left
                          footer-paragraph"
                          >
                            Email: contact@luminartechnolab.com
                          </p>
                        </a>
                        {/* <span class="footer-phone">Phone: +91 484 405 8886 </span> */}
                      </p>
                      {/* thiruvandrum */}

                      <p class="course1 pt-2 px-0">Trivandrum </p>
                      <p class="text-left footer-address">
                        Luminar Technolab Pvt Ltd <br />
                        3rd Floor, Nikunjam Saras Building,
                        <br />
                        opp. Kerala PSC office, <br />
                        Pattom, <br />
                        Thiruvananthapuram, Kerala 695004 <br />
                        <a href="tel:+91 808 665 1651">
                          <p class="text-left footer-paragraph">
                            Call: +91 6235651651
                          </p>
                        </a>
                        <a href="mailto:contact@luminartechnolab.com">
                          <p class="text-left footer-paragraph">
                            Email: trivandrum@luminartechnolab.com
                          </p>
                        </a>
                      </p>
                      {/*  */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-3 footer-web-useful-links">
              <p class="course pt-2 px-0">USEFUL LINKS</p>
              <hr class="footer-hr" />
              <div
                class="row footer-courses
                                            about-our-services text-left"
              >
                <div
                  class="col-sm-12
                                                footer-courses-our-facility"
                >
                  <Link to="/about" className="footer-link">
                    Teachers & Staff
                  </Link>
                  <Link to="/gallery" className="footer-link">
                    Gallery
                  </Link>
                </div>

                <div
                  class="col-sm-12
                                                footer-courses-our-facility"
                >
                  <Link to="/courses" className="footer-link">
                    Our Courses
                  </Link>
                  <Link to="/Refund" className="footer-link-01">
                    Refund & Cancellation Policy
                  </Link>
                </div>

                <div
                  class="col-sm-12
                                                footer-courses-our-facility"
                >
                  <Link to="/certification" className="footer-link-01">
                    Certification & Affiliation
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-sm-3 p-0 footer-web-contact-address ">
              <p class="course pt-2 px-0">CONTACT US</p>
              <hr class="footer-hr" />
              <p class="course pt-2 px-0">Cochin</p>
              <p class="text-left footer-address">
                Luminar Technolab Pvt Ltd
                <br />
                Ground Floor,K7 REALTY,
                <br />
                Juma masjid road, <br />
                Seaport - Airport Rd, near Opp: District Panchayat, <br />
                Kakkanad, Kochi, Kerala - 682030 <br />
                <a href="tel:+91 808 665 1651">
                  <p class="text-left footer-paragraph">
                    Call:+91 8086 651 651
                  </p>
                </a>
                <a href="mailto:contact@luminartechnolab.com">
                  <p class="text-left footer-paragraph">
                    Email: contact@luminartechnolab.com
                  </p>
                </a>
                {/* <span class="footer-phone">Phone: +91 484 405 8886 </span> */}
              </p>

              <p class="course pt-2 px-0">Calicut</p>

              <p class="text-left footer-address">
                Luminar Technolab Pvt Ltd <br />
                2nd Floor, 61/10222 ,<br />
                Mullath Building, <br />
                Opp. KSRTC Bus Stand, <br />
                <span style={{ whiteSpace: "nowrap" }}>
                  {" "}
                  Mavoor Road, Calicut, Kerala-673001
                </span>
                <a href="tel:+91 808 653 1531">
                  <p class="text-left footer-paragraph">
                    Call: +91 8086 531 531, 0495 - 3573001
                  </p>
                </a>
                <a href="mailto:contact@luminartechnolab.com">
                  <p class="text-left footer-paragraph">
                    Email: contact@luminartechnolab.com
                  </p>
                </a>
                {/* <span class="footer-phone">Phone: +91 484 405 8886 </span> */}
                <p class="course pt-2 px-0">Trivandrum </p>
                <p class="text-left footer-address">
                  Luminar Technolab Pvt Ltd <br />
                  3rd Floor, Nikunjam Saras Building,
                  <br />
                  opp. Kerala PSC office,Pattom, <br />
                  Thiruvananthapuram, Kerala 695004 <br />
                  <a href="tel:+91 808 665 1651">
                    <p class="text-left footer-paragraph">
                      Call: +91 6235651651
                    </p>
                  </a>
                  <a href="mailto:contact@luminartechnolab.com">
                    <p class="text-left footer-paragraph">
                      Email: trivandrum@luminartechnolab.com
                    </p>
                  </a>
                </p>
              </p>
            </div>
          </div>
        </div>

        <div class="footer-wrapper px-0">
          <div class="container text-center">
            <div class="row">
              <div class="col-12">
                <p class="footer-copyrights">
                  {" "}
                  © Luminar Technolab {new Date().getFullYear()} | Developed by{" "}
                  <a href="https://www.deepnetsoft.com/" target="_blank">
                    Deepnetsoft
                  </a>
                  <Link to="/privacyPolicy" class="terms-and-use">
                    {" "}
                    Privacy
                  </Link>{" "}
                  &
                  <Link to="/termsAndConditions" class="terms-and-use">
                    {" "}
                    Terms of Use{" "}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
